import React from 'react';
import HomeContent from '../component/home/HomeContent';

const Home = () => {
    return (
        <React.Fragment>
            <HomeContent />
        </React.Fragment>
    )
}

export default Home;