import * as React from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ColorModeContext } from './context';
import useMediaQuery from '@mui/material/useMediaQuery';
import themePalette from "./themes/palette";
import MainRoutes from "./routes";
import customStyles from './themes/custom';
import themeBreakPoints from './themes/breakpoint';
import themeComponent from './themes/component';
import { Provider } from 'react-redux';
import store from './store/store';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');
  const themeMode = React.useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  React.useEffect(
    () => {
      setMode(prefersDarkMode ? 'dark' : 'light');
    },
    [prefersDarkMode]
  )

  const theme = React.useMemo(
    () => 
    createTheme({
      palette: themePalette(mode),
      components: themeComponent(mode),
      breakpoints: themeBreakPoints(),
      custom: customStyles(mode),
      typography: {
        fontFamily: [
          'Satoshi',
          'Ubuntu-Regular',
          '"Helvetica Neue"',
          'Condensed',
          'DisplayRegular',
          'Helvetica',
          'Arial',
          '"PingFang SC"',
          '"Hiragino Sans GB"',
          '"WenQuanYi Micro Hei"',
          '"Microsoft Yahei"',
          'sans-serif',
        ].join(','),
      }
    }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={themeMode}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <MainRoutes /> 
        </Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
