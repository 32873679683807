import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import providerArrowIcon from '../../assets/images/home/home-provider-arrow.png';
import supportIcon1 from '../../assets/images/home/home-advantage-support1.png';
import supportIcon2 from '../../assets/images/home/home-advantage-support2.png';
import bannerIcon from '../../assets/images/home/home-advantage-support3.png';
import bannerIcon2 from '../../assets/images/home/home-advantage-support4.png';
import HomeContentMainAdvantageItem from './HomeContentMainAdvantageItem';

const HomeContentMainAdvantage = () => {
    const { t } = useTranslation();
    const textData = {
        tt24: t("tt24"),
        tt25: t("tt25"),
        tt26: t("tt26"),
        tt27: t("tt27"),
        tt28: t("tt28"),
        tt29: t("tt29"),
        tt30: t("tt30"),
        tt31: t("tt31"),
        tt32: t("tt32"),
        tt33: t("tt33"),
        tt34: t("tt34"),
        tt35: t("tt35"),
        tt36: t("tt36"),
    };

    const supportArr = [
        {
            image:supportIcon1,
            title:textData.tt25,
            desc:textData.tt26,
            bannerTitle:'',
            bannerDesc:'',
        },
        {
            image:supportIcon2,
            title:textData.tt27,
            desc:textData.tt28,
            bannerTitle:'',
            bannerDesc:'',
        },
    ];
    const banner = {
        image:bannerIcon,
        title:textData.tt33,
        desc:textData.tt34,
        bannerTitle:textData.tt35,
        bannerDesc:textData.tt36,
    };
    const banner2 = {
        image:bannerIcon2,
        title:textData.tt29,
        desc:textData.tt30,
        bannerTitle:textData.tt31,
        bannerDesc:textData.tt32,
    };

    return (
        <Grid sx={{
            padding:'48px 100px',
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'928px',
                }
            }}>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    gap:'18px',
                }}>
                    <Typography sx={{
                        color:'#000000',
                        fontSize:'31px',
                        fontStyle:'normal',
                        fontWeight:700,
                    }}>
                        {textData.tt24}
                    </Typography>
                    {/* <Button disableRipple sx={{
                        textTransform:'none',
                        bgcolor:'transparent',
                        color:'#000000',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:700,
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'8px',
                    }}>
                        {'View all episodes'}
                        <CardMedia
                            component="img"
                            image={providerArrowIcon}
                            sx={{
                                width:'12px',
                                height:'8px',
                                objectFit:'contain',
                            }}
                        />
                    </Button> */}
                </Grid>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                    gap:'18px',
                    mt:'28px',
                }}>
                    {supportArr.map((item,index) => (
                        <HomeContentMainAdvantageItem
                            item={item}
                            key={index}
                        />
                    ))}
                </Grid>
                <HomeContentMainAdvantageItem 
                    item={banner2}
                    isBanner={true}
                />
                <HomeContentMainAdvantageItem 
                    item={banner}
                    isBanner={true}
                />
            </Container>
        </Grid>
    );
}

export default HomeContentMainAdvantage;