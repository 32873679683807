import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Menu, { menuClasses }from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { paperClasses } from '@mui/material/Paper';
import { popoverClasses } from '@mui/material/Popover';
import searchIcon from '../../assets/images/header/header-search.png';
import arrowIcon from '../../assets/images/header/header-arrow-right.png';
import languageEN from '../../assets/images/header/header-language-en.png';
import languageKO from '../../assets/images/header/header-language-ko.png';
import languageSG from '../../assets/images/header/header-language-sg.png';
import languageBR from '../../assets/images/header/header-language-br.png';
import languageES from '../../assets/images/header/header-language-es.png';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      borderRadius: 8,
      marginTop: 10,
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: 'px 0',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'start',
        gap:'5px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#000000',
            0.8,
          ),
        },
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#0C0D11',
    }
}));

const Header = (props) => {
    const {
        menuIndex,type,
    } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();
    const textData = {
        tt1: t("tt1"),
        tt2: t("tt2"),
        tt3: t("tt3"),
        tt5: t("tt5"),
        tt6: t("tt6"),
    };

    const leftTabTitleArr = [
        textData.tt1,textData.tt2,textData.tt3,
    ];
    const languageMenuItemArr = [
        {
            language:'en',
            languageText:'English',
            languageIcon:languageEN,
        },
        {
            language:'ko',
            languageText:'한국인',
            languageIcon:languageKO,
        },
        {
            language:'zh',
            languageText:'繁體中文',
            languageIcon:languageSG,
        },
        {
            language:'pt',
            languageText:'Português',
            languageIcon:languageBR,
        },
        {
            language:'es',
            languageText:'Español',
            languageIcon:languageES,
        },
    ];

    const chooseLanguageImage = () => {
        if(i18n.language.toLowerCase().includes("ko")) {
            return languageKO;
        }else if(i18n.language.toLowerCase().includes("zh")) {
            return languageSG;
        }else if(i18n.language.toLowerCase().includes("pt")) {
            return languageBR;
        }else if(i18n.language.toLowerCase().includes("es")) {
            return languageES;
        }else {
            return languageEN;
        }
    }
    const chooseLanguageText = () => {
        if(i18n.language.toLowerCase().includes("ko")) {
            return '한국인';
        }else if(i18n.language.toLowerCase().includes("zh")) {
            return '繁體中文';
        }else if(i18n.language.toLowerCase().includes("pt")) {
            return 'Português';
        }else if(i18n.language.toLowerCase().includes("es")) {
            return 'Español';
        }else {
            return 'English';
        }
    }
    const [ languageIcon, setLanguageIcon] = useState(chooseLanguageImage());
    const [ languageText, setLanguageText] = useState(chooseLanguageText());

    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTabClick = (index) => {
        if(index === type) {
            return;
        }

        if(index === 0) {
            navigate(RoutePath.home);
        }else if(index === 1) {
            navigate(RoutePath.template,{ state: { type:0, } });    
        }else if(index === 2) {
            navigate(RoutePath.template,{ state: { type:1, } });  
        }
    };
    const handleDemoClick = () => {
        const link = document.createElement('a');
        link.href = 'https://www.ttbetgame.com/';
        link.target = '_blank';
        link.click();
    };
    const handleContactUsClick = () => {

    };
    const handleChangeLanguage = (lang) => {
        handleClose();
        if(!i18n.language.toLowerCase().includes(lang)) {
            i18n.changeLanguage(lang);
            if(lang === 'ko') {
                setLanguageIcon(languageKO);
                setLanguageText('한국인');
            }else if(lang === 'zh') {
                setLanguageIcon(languageSG);
                setLanguageText('繁體中文');
            }else if(lang === 'pt') {
                setLanguageIcon(languageBR);
                setLanguageText('Português');
            }else if(lang === 'es') {
                setLanguageIcon(languageES);
                setLanguageText('Español');
            }else {
                setLanguageIcon(languageEN);
                setLanguageText('English');
            }
        }
    }
    
    return (
        <React.Fragment>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                gap:'18px',
            }}> 
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                }}>
                    {leftTabTitleArr.map((item,index) => (
                        <Button variant='text' disableRipple sx={{
                            textTransform:'none',
                            bgcolor:'transparent',
                            color:'#FFFFFF',
                            fontSize:menuIndex === index ? '18px' : '12px',
                            fontStyle:'normal',
                            fontWeight:700,
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'flex-start',
                            padding:index === 0 ? '0px 14px 0px 0px' : '0px 14px',
                        }} onClick={() => handleTabClick(index)}>
                            {item}
                        </Button>
                    ))}
                </Box>

                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'center',
                }}>
                    <Button disableRipple sx={{
                        textTransform:'none',
                        bgcolor:'transparent',
                        color:'#FFFFFF',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:700,
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'4px',
                        padding:'0px',
                    }} onClick={handleDemoClick}>
                        <CardMedia
                            component="img"
                            image={searchIcon}
                            sx={{
                                width:'10px',
                                height:'13px',
                                objectFit:'contain',
                            }}
                        />
                        {textData.tt5}
                    </Button>
                    <Button disableRipple sx={{
                        textTransform:'none',
                        bgcolor:'transparent',
                        color:'#FFFFFF',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:700,
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'4px',
                        padding:'0px',
                        ml:'14px',
                    }} onClick={handleContactUsClick}>
                        <CardMedia
                            component="img"
                            image={searchIcon}
                            sx={{
                                width:'10px',
                                height:'13px',
                                objectFit:'contain',
                            }}
                        />
                        {textData.tt6}
                    </Button>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        cursor:'pointer',
                        ml:'30px',
                    }} onClick={handleClick}>
                        <CardMedia
                            component="img"
                            image={languageIcon}
                            sx={{
                                width:'32px',
                                height:'23px',
                                objectFit:'contain',
                            }}
                        />
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'16px',
                            fontStyle:'normal',
                            fontWeight:400,
                            ml:'12px',
                        }}>
                            {languageText}
                        </Typography>
                        <CardMedia
                            component="img"
                            image={arrowIcon}
                            sx={{
                                width:'8px',
                                height:'18px',
                                objectFit:'contain',
                                ml:'36px',
                            }}
                        />
                    </Box>
                </Box>
            </Grid>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {languageMenuItemArr.map((item,index) => (
                    <MenuItem onClick={() => handleChangeLanguage(item.language)} disableRipple sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'12px',
                        color:'#FFFFFF',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:400,
                    }} key={item}>
                        <CardMedia
                            component="img"
                            image={ item.languageIcon }
                            sx={{
                                width:'32px',
                                height:'23px',
                                objectFit:'contain',
                            }}
                        />
                        {item.languageText}
                    </MenuItem>
                ))}
            </StyledMenu>
        </React.Fragment>
    );
}

export default Header;