import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from 'swiper/modules';
import './HomeContentMainSwiperStyle.css';
import bannerIcon1 from '../../assets/images/home/home-scroll-banner1.png';
import bannerIcon2 from '../../assets/images/home/home-scroll-banner2.png';
import bannerIcon3 from '../../assets/images/home/home-scroll-banner3.png';
import providerArrowIcon from '../../assets/images/home/home-provider-arrow.png';

const HomeContentMainSwiper = () => {
    const { t } = useTranslation();
    const textData = {
        tt39: t("tt39"),
        tt40: t("tt40"),
        tt41: t("tt41"),
        tt42: t("tt42"),
        tt43: t("tt43"),
        tt44: t("tt44"),
    };

    const itemArr = [
        {
            image:bannerIcon1,
            title:textData.tt39,
            desc:textData.tt40,
        },
        {
            image:bannerIcon2,
            title:textData.tt41,
            desc:textData.tt42,
        },
        {
            image:bannerIcon3,
            title:textData.tt43,
            desc:textData.tt44,
        },
    ];
    itemArr.push(...itemArr);

    const handleBannerClick = (index) => {

    }

    return (
        <Box sx={{
            mt:'7px',
            width:'100%',
            position:'relative',
        }}>
            <Swiper 
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={40}
                pagination={{
                  clickable: true
                }}
                modules={[Autoplay, Pagination]}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
                }}
                style={{
                    width:'100%',
                    '--swiper-pagination-bottom': '10px',
                }}
            >
                {itemArr.map((item,index) => (
                    <SwiperSlide key={index} style={{
                        width:'calc(50% - 80px)',
                        padding:'15px 0px 60px 0px',
                    }}>
                        <Box sx={{
                            bgcolor:'#FFFFFF',
                            borderRadius:'18px',
                            boxShadow:'0px 0px 15.625px 0px rgba(0, 0, 0, 0.08)',
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            width:'100%',
                            overflow:'hidden',
                            // height:'245px',
                        }}>
                            <CardMedia
                                component="img"
                                image={item.image}
                                sx={{
                                    width:'245px',
                                    height:'245px',
                                    objectFit:'cover',
                                }}
                            />
                            <Box sx={{
                                width:'calc(100% - 245px - 54px)',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                padding:'10px 27px',
                            }}>
                                <Typography sx={{
                                    color:'#000000',
                                    fontSize:'18px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{
                                    color:'#8E8B8C',
                                    fontSize:'12px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    mt:'8px',
                                }}>
                                    {item.desc}
                                </Typography>
                                {/* <Button disableRipple sx={{
                                    textTransform:'none',
                                    bgcolor:'transparent',
                                    color:'#000000',
                                    fontSize:'12px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    padding:'0px',
                                    gap:'8px',
                                    mt:'8px',
                                }} onClick={() => handleBannerClick(index)}>
                                    {'Episode page'}
                                    <CardMedia
                                        component="img"
                                        image={providerArrowIcon}
                                        sx={{
                                            width:'12px',
                                            height:'8px',
                                            objectFit:'contain',
                                        }}
                                    />
                                </Button> */}
                            </Box>
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default HomeContentMainSwiper;
