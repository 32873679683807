const Breakpoints = () => ({
    values: {
        xs: 0,
        sm: 600,
        md: 850,
        lg: 1200,
        xl: 1536,
    },
});

export default Breakpoints;