import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import shareIcon from '../../assets/images/home/home-advantage-share.png';

const HomeContentMainAdvantageItem = (props) => {
    const {
        item,isBanner,
    } = props;
    const { image,title,desc,bannerTitle,bannerDesc, } = item;

    return (
        <Box sx={{
            bgcolor:'#FFFFFF',
            borderRadius:'6px',
            boxShadow:'0px 0px 15.625px 0px rgba(0, 0, 0, 0.08)',
            width:isBanner ? '100%' : '436px',
            mt:isBanner ? '18px' : '0px',
            padding:'0px 0px 21px 0px',
        }}>
            <Box sx={{
                position:'relative',
                width:'100%',
                height:'242px',
                borderRadius:'18px',
                overflow:'hidden',
            }}>
                <CardMedia
                    component="img"
                    image={image}
                    sx={{
                        position:'absolute',
                        top:'0px',
                        left:'0px',
                        width:'100%',
                        height:'100%',
                        objectFit:'cover',
                    }}
                />
                <Box sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    gap:'10px',
                    padding:'10px 0px',
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'30px',
                        fontStyle:'normal',
                        fontWeight:700,
                        lineHeight:'30px',
                        margin:'0px 77px',
                    }}>
                        {bannerTitle}
                    </Typography>
                    <Typography sx={{
                        color:'#7CB1FF',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:700,
                        lineHeight:'28px',
                        margin:'0px 77px',
                    }}>
                        {bannerDesc}
                    </Typography>
                </Box>
            </Box>
            <Typography sx={{
                color:'#000000',
                fontSize:'20px',
                fontStyle:'normal',
                fontWeight:700,
                mt:'21px',
                ml:'24px',
            }}>
                {title}
            </Typography>
            <Typography sx={{
                color:'#8E8B8C',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:400,
                mt:'10px',
                padding:'0px 24px',
                width:'calc(100% - 48px)',
                whiteSpace:'pre-line',
            }}>
                {desc}
            </Typography>
            {/* <Button disableRipple sx={{
                textTransform:'none',
                bgcolor:'transparent',
                color:'#8E8B8C',
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:400,
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'8px',
                padding:'0px',
                ml:'24px',
                mt:'15px',
                mb:'21px',
            }}>
                {'Share'}
                <CardMedia
                    component="img"
                    image={shareIcon}
                    sx={{
                        width:'18px',
                        height:'18px',
                        objectFit:'contain',
                    }}
                />
            </Button> */}
        </Box>
    );
}

export default HomeContentMainAdvantageItem;