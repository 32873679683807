
const themePalette = (mode) => {
    return {
      mode: mode,
      primary: {
        main: mode === 'dark' ? '#B22A28' : '#B22A28'
      },
      secondary: {
        light: '#848484',
        main: '#1c1c1e'
      },
      text: {
        main: '#fff',
        secondary: '#848484'
      }
    };
  };
  
  export default themePalette;