import { configureStore } from '@reduxjs/toolkit';
import appSlice from '../features/appSlice';
import templateSlice from '../features/templateSlice';

const store = configureStore({
    reducer: {
        app: appSlice,
        template: templateSlice,
    }
})

export default store;