import React from 'react';
import { BrowserRouter,Route, Routes } from "react-router-dom";
import RoutePath from '../tools/RoutePath';
import Home from "../pages/Home";
import Template from '../pages/Template';

const MainRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={RoutePath.home} element={<Home/>}/>
                <Route path={RoutePath.template} element={<Template/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default MainRoutes;