import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import arrowIcon from '../../assets/images/common/common-arrow-left.png';
import { useDispatch, useSelector } from 'react-redux';
import { webSettingOperate } from '../../features/appSlice';
import TemplateContentWebSettingItem from './TemplateContentWebSettingItem';
import TemplateContentWebSettingTitle from './TemplateContentWebSettingTitle';
import TemplateContentWebSettingBasicInput from './TemplateContentWebSettingBasicInput';
import TemplateContentWebSettingSelect from './TemplateContentWebSettingSelect';
import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';
import { setCountryTelCode, setCurrency, setDefaultLanguage, setLanguages, setWebsiteID, setWebsiteName } from '../../features/templateSlice';

const TemplateContentWebSetting = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        tt58: t("tt58"),
        tt63: t("tt63"),
        tt64: t("tt64"),
        tt65: t("tt65"),
        tt66: t("tt66"),
        tt67: t("tt67"),
        tt68: t("tt68"),
        tt71: t("tt71"),
        tt72: t("tt72"),
        tt73: t("tt73"),
        tt74: t("tt74"),
        tt75: t("tt75"),
        tt76: t("tt76"),
        tt77: t("tt77"),
        tt78: t("tt78"),
        tt79: t("tt79"),
        tt80: t("tt80"),
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ desktopAdded,setDesktopAdded ] = useState(true);
    const [ mobileAdded,setMobileAdded ] = useState(true);
    const [ basicAdded,setBasicAdded ] = useState(true);
    const [ languageAdded,setLanguageAdded ] = useState(true);    

    const handleCloseWebSettingClick = () => {
        dispatch(webSettingOperate({webSettingOpen:false}));
    };
    const handleDesktopClick = () => {
        setDesktopAdded(!desktopAdded);
    };
    const handleMobileClick = () => {
        setMobileAdded(!mobileAdded);
    };
    const handleBasicInfoClick = () => {
        setBasicAdded(!basicAdded);
    };
    const handleLanguageClick = () => {
        setLanguageAdded(!languageAdded);
    };

    const handleWebsiteNameInputChanged = (e) => {
        dispatch(setWebsiteName({ websiteName: e.target.value, }));
    };
    const handleWebsiteIDInputChanged = (e) => {
        dispatch(setWebsiteID({ websiteID: e.target.value, }));
    };

    const handleBrowserClick = (type) => {
        handleCloseWebSettingClick();

        if(type === 0) {
            navigate(RoutePath.template,{ state: { type:0, } });    
        }else {
            navigate(RoutePath.template,{ state: { type:1, } });  
        }
    };

    // const [ languageAreaHeight,setLanguageAreaHeight ] = useState(332);
    const [ openMenuIndex,setOpenMenuIndex ] = useState(-1);
    const [ focusIndex,setFocusIndex ] = useState(-1);
    // const [ websiteName,setWebsiteName ] = useState('');
    // const [ websiteID,setWebsiteID ] = useState('');
    // const [ chooseLanguage,setChooseLanguage ] = useState([]);
    // const [ chooseDefaultLanguage,setChooseDefaultLanguage ] = useState('');
    // const [ chooseCurrency,setChooseCurrency ] = useState('');
    // const [ chooseCountryCode,setChooseCountryCode ] = useState('');

    const websiteName = useSelector((state) => state.template.websiteName);
    const websiteID = useSelector((state) => state.template.websiteID);
    const chooseLanguage = useSelector((state) => state.template.languages);
    const chooseDefaultLanguage = useSelector((state) => state.template.defaultLanguage);
    const chooseCurrency = useSelector((state) => state.template.currency);
    const chooseCountryCode = useSelector((state) => state.template.countryTelCode);
    console.log('chooseDefaultLanguage:',chooseDefaultLanguage);

    const chooseItemArr = [
        {
            title:textData.tt76,
            isMultiChoose:true,
            chooseMenuArr:['EN','KO','ZH','PT','ES','VN','ID','MX','KH','PH'],
            currentSelected:chooseLanguage.length > 0 ? chooseLanguage : [],
        },
        {
            title:textData.tt77,
            isMultiChoose:false,
            chooseMenuArr:['EN','KO','ZH','PT','ES','VN','ID','MX','KH','PH'],
            currentSelected:(chooseDefaultLanguage !== undefined && chooseDefaultLanguage.length > 0) ? [chooseDefaultLanguage] : [],
        },
        {
            title:textData.tt78,
            isMultiChoose:false,
            chooseMenuArr:['USDT','EUR','IDR','PTS','PHP'],
            currentSelected:(chooseCurrency !== undefined && chooseCurrency.length > 0) ? [chooseCurrency] : [],
        },
        {
            title:textData.tt79,
            isMultiChoose:false,
            chooseMenuArr:['th','vn','us','pt','es'],
            currentSelected:(chooseCountryCode !== undefined && chooseCountryCode.length > 0) ? [chooseCountryCode] : [],
        },
    ];

    const handleMenuOpenClick = (open,menuHeight,index) => {
        setOpenMenuIndex(index);
        setFocusIndex(index);
        // setLanguageAreaHeight(open ? (332 + menuHeight) : 332);
    };
    const handleChooseArrClick = (chooseArr,index) => {
        if(index === 0) {
            dispatch(setLanguages({ languages: chooseArr, }));
        }else if(index === 1) {
            dispatch(setDefaultLanguage({ defaultLanguage: chooseArr[0], }));
        }else if(index === 2) {
            dispatch(setCurrency({ currency: chooseArr[0], }));
        }else if(index === 3) {
            dispatch(setCountryTelCode({ countryTelCode: chooseArr[0], }));
        }
    };
    
    const selectTemplate = useSelector((state) => state.template);
    const handleDownloadClick = () => {
        const objectToSave = { 
            defaultLocale: chooseDefaultLanguage,
            languages: chooseLanguage,
            defaultCurrency: chooseCurrency,
            defaultTelCountry: chooseCountryCode,
            name: websiteName,
            code: websiteID,
        };
        const fileContent1 = `module.exports = ${JSON.stringify(selectTemplate, null, 2)};`;
        const fileContent2 = `module.exports =  ${JSON.stringify(objectToSave, null, 2)};`;
        const zip = new JSZip();
        zip.file('Template.js', fileContent1);
        zip.file('Ims2.js', fileContent2);

        zip.generateAsync({ type: 'blob' }).then((blob) => {
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'TTConfig.zip';
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };

    return (
        <Box sx={{
            width:'548px',
            height:'calc(100% - 100px)',
            padding:'50px 0px',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'flex-start',
        }}>
            <Button variant='extended' onClick={handleCloseWebSettingClick} sx={{
                bgcolor:'#1F3244',
                width:'120px',
                height:'87px',
                borderRadius:'18px 0px 0px 18px',
                boxShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.40)',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                padding:'0px',
                overflow:'hidden',
                mt:'88px',
                '&:hover': {
                    bgcolor:'#1F3244',
                },
            }}>
                <Box sx={{
                    bgcolor:'#344352',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'21px',
                    height:'100%',
                }}>
                    <CardMedia
                        component="img"
                        image={arrowIcon}
                        sx={{
                            width:'9px',
                            height:'20px',
                            objectFit:'contain',
                            transform:'rotate(180deg)'
                        }}
                    />
                </Box>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'98px',
                    height:'100%',
                    padding:'5px',
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'20px',
                        fontStyle:'normal',
                        fontWeight:700,
                        textAlign:'center',
                        textOverflow:'ellipsis',
                        lineHeight:'normal',
                        overflow:'hidden',
                        textTransform:'none',
                    }}>
                        {textData.tt58}
                    </Typography>
                </Box>
            </Button>
            <Box sx={{
                bgcolor:'#FFFFFF',
                borderRadius:'26px',
                height:'calc(100% - 112px)',
                width:'428px',
                position:'relative',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                padding:'18px 36px 94px 36px',
                overflow:'scroll',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:700,
                }}>
                    {textData.tt63}
                </Typography>
                <Typography sx={{
                    color:'#8E8B8C',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    mt:'5px',
                }}>
                    {textData.tt64}
                </Typography>
                {/* 桌面版本 */}
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    mt:'18px',
                }}>
                    <TemplateContentWebSettingTitle 
                        title={textData.tt65}
                        added={!desktopAdded}
                        handleOnClick={handleDesktopClick}
                    />
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        mt:'8px',
                        overflow:'hidden',
                        width:'100%',
                    }}>
                        <TemplateContentWebSettingItem 
                            type={1}
                            title={textData.tt66}
                            isShow={desktopAdded}
                            browserOnClick={() => handleBrowserClick(1)}
                        />
                    </Box>
                </Box>
                {/* 移动模版 */}
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    mt:'18px',
                }}>
                    <TemplateContentWebSettingTitle 
                        title={textData.tt67}
                        added={!mobileAdded}
                        handleOnClick={handleMobileClick}
                    />
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        mt:'8px',
                        overflow:'hidden',
                        width:'100%',
                    }}>
                        <TemplateContentWebSettingItem 
                            type={0}
                            title={textData.tt68}
                            isShow={mobileAdded}
                            browserOnClick={() => handleBrowserClick(0)}
                        />
                    </Box>
                </Box>
                {/* 基本信息 */}
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    mt:'18px',
                }}>
                    <TemplateContentWebSettingTitle 
                        title={textData.tt71}
                        added={!basicAdded}
                        handleOnClick={handleBasicInfoClick}
                    />
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        overflow:'hidden',
                        width:'100%',
                        mt:'16px',
                    }}>
                        <Box sx={{
                            width:'100%',
                            height:basicAdded ? '167px' : '0px',
                            transformOrigin:'50% 0 0',
                            transition: theme.transitions.create(['height'], {
                                duration: 300,
                                easing: 'ease-in-out',
                            }),
                        }}>
                            <TemplateContentWebSettingBasicInput 
                                title={textData.tt72}
                                defaultText={websiteName}
                                handleInputChanged={handleWebsiteNameInputChanged}
                            />
                            <Box sx={{
                                mt:'16px',
                                width:'100%',
                            }}>
                                <TemplateContentWebSettingBasicInput 
                                    title={textData.tt73}
                                    defaultText={websiteID}
                                    filterMatch={/[^a-z0-9]/g}
                                    handleInputChanged={handleWebsiteIDInputChanged}
                                />
                            </Box>
                            <Typography sx={{
                                color:'#8E8B8C',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:400,
                                mt:'8px',
                            }}>
                                {textData.tt74}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {/* 语言和货币 */}
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    mt:'18px',
                }}>
                    <TemplateContentWebSettingTitle 
                        title={textData.tt75}
                        added={!languageAdded}
                        handleOnClick={handleLanguageClick}
                    />
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        overflow:'hidden',
                        width:'100%',
                        // mt:'16px',
                    }}>
                        <Box sx={{
                            width:'100%',
                            height:languageAdded ? `auto` : '0px',
                            transformOrigin:'50% 0 0',
                            transition: theme.transitions.create(['height'], {
                                duration: 300,
                                easing: 'ease-in-out',
                            }),
                        }}>
                            {chooseItemArr.map((item,index) => (
                                <TemplateContentWebSettingSelect 
                                    title={item.title}
                                    isMultiChoose={item.isMultiChoose}
                                    isChoose={openMenuIndex === index ? true : false}
                                    isFocus={focusIndex === index ? true : false}
                                    chooseMenuArr={item.chooseMenuArr}
                                    currentSelected={item.currentSelected}
                                    handleChooseArr={(chooseArr) => handleChooseArrClick(chooseArr,index)}
                                    handleOpen={(open,menuHeight) => handleMenuOpenClick(open,menuHeight,index)}
                                    handleFocus={() => setFocusIndex(index)}
                                    key={index}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            
            <Box sx={{
                position:'absolute',
                bottom:'50px',
                left:'104px',
                bgcolor:'#FFFFFF',
                width:'calc(100% - 104px - 36px)',
                padding:'18px',
                borderRadius:'0px 0px 26px 26px',
            }}>
                <Button sx={{
                    textTransform:'none',
                    bgcolor:'#344352',
                    height:'58px',
                    borderRadius:'29px',
                    width:'100%',
                    color:'#FFFFFF',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:700,
                    '&:hover': {
                        bgcolor:'#344352',
                    }
                }} onClick={handleDownloadClick}>
                    {textData.tt80}
                </Button>
            </Box>
        </Box>
    );
}

export default TemplateContentWebSetting;