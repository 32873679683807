import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import arrowIcon from '../../assets/images/common/common-arrow-left.png';
import TemplateContentWebSetting from '../template/TemplateContentWebSetting';
import { useDispatch, useSelector } from 'react-redux';
import { webSettingOperate } from '../../features/appSlice';

const WebSetting = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        tt58: t("tt58"),
    };

    const dispatch = useDispatch();

    const handleWebSettingClick = () => {
        dispatch(webSettingOperate({webSettingOpen:true}));
    };
    const handleCloseDrawerClick = () => {
        dispatch(webSettingOperate({webSettingOpen:false}));
    };

    const webSettingOpen = useSelector((state) => state.app.webSettingOpen);

    return (
        <React.Fragment>
            <Drawer
                anchor='right'
                open={webSettingOpen}
                onClose={handleCloseDrawerClick}
                sx={{
                    [`& .${drawerClasses.paper}`]: {
                        bgcolor:'transparent',
                        boxShadow:'none',
                    },
                }}
            >
                <TemplateContentWebSetting />
            </Drawer>

            <Fab variant='extended' onClick={handleWebSettingClick} sx={{
                bgcolor:'#1F3244',
                position:'fixed',
                top:'138px',
                right:'0px',
                float:'right',
                width:'119px',
                height:'87px',
                borderRadius:'18px 0px 0px 18px',
                boxShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.40)',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                padding:'0px',
                overflow:'hidden',
                '&:hover': {
                    bgcolor:'#1F3244',
                },
            }}>
                <Box sx={{
                    bgcolor:'#344352',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'21px',
                    height:'100%',
                }}>
                    <CardMedia
                        component="img"
                        image={arrowIcon}
                        sx={{
                            width:'9px',
                            height:'20px',
                            objectFit:'contain',
                        }}
                    />
                </Box>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'98px',
                    height:'100%',
                    padding:'5px',
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'20px',
                        fontStyle:'normal',
                        fontWeight:700,
                        textAlign:'center',
                        textOverflow:'ellipsis',
                        lineHeight:'normal',
                        overflow:'hidden',
                        textTransform:'none',
                    }}>
                        {textData.tt58}
                    </Typography>
                </Box>
            </Fab>
        </React.Fragment>
    );
}

export default WebSetting;