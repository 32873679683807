import React from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import TemplateContentHeader from './TemplateContentHeader';
import TemplateContentMain from './TemplateContentMain';
import bannerIcon from '../../assets/images/template/template-temp-banner.png';
import Footer from '../footer/Footer';
import FooterBanner from '../footer/FooterBanner';

const TemplateContent = (props) => {
    const {
        type,
    } = props;

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid>
            <TemplateContentHeader type={type}/>
            <TemplateContentMain type={type} />
            <FooterBanner />
            <Footer />
        </Grid>
    );
}

export default TemplateContent;