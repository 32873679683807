import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import providerArrowIcon from '../../assets/images/home/home-provider-arrow.png';
import mobileItem1 from '../../assets/images/template/mobile/template-desktop-item1.webp';
import mobileItem2 from '../../assets/images/template/mobile/template-desktop-item2.webp';
import mobileItem3 from '../../assets/images/template/mobile/template-desktop-item3.webp';
import mobileItem4 from '../../assets/images/template/mobile/template-desktop-item4.webp';
import mobileItem5 from '../../assets/images/template/mobile/template-desktop-item5.webp';
import mobileItem6 from '../../assets/images/template/mobile/template-desktop-item6.webp';
import mobileItem7 from '../../assets/images/template/mobile/template-desktop-item7.webp';
import mobileItem8 from '../../assets/images/template/mobile/template-desktop-item8.webp';
import mobileItem9 from '../../assets/images/template/mobile/template-desktop-item9.webp';
import mobileItem10 from '../../assets/images/template/mobile/template-desktop-item10.webp';
import mobileItem11 from '../../assets/images/template/mobile/template-desktop-item11.webp';
import mobileItem12 from '../../assets/images/template/mobile/template-desktop-item12.webp';
import mobileItem13 from '../../assets/images/template/mobile/template-desktop-item13.webp';
import mobileItem14 from '../../assets/images/template/mobile/template-desktop-item14.webp';
import mobileItem15 from '../../assets/images/template/mobile/template-desktop-item15.webp';
import mobileItem16 from '../../assets/images/template/mobile/template-desktop-item16.webp';
import mobileItem17 from '../../assets/images/template/mobile/template-desktop-item17.webp';
import mobileItem18 from '../../assets/images/template/mobile/template-desktop-item18.webp';
import mobileItem19 from '../../assets/images/template/mobile/template-desktop-item19.webp';
import mobileItem20 from '../../assets/images/template/mobile/template-desktop-item20.webp';
import mobileItem21 from '../../assets/images/template/mobile/template-desktop-item21.webp';
import mobileItem22 from '../../assets/images/template/mobile/template-desktop-item22.webp';
import mobileItem23 from '../../assets/images/template/mobile/template-desktop-item23.webp';
import mobileItem24 from '../../assets/images/template/mobile/template-desktop-item24.webp';
import mobileItem25 from '../../assets/images/template/mobile/template-desktop-item25.webp';
import mobileItem26 from '../../assets/images/template/mobile/template-desktop-item26.webp';
import mobileItem27 from '../../assets/images/template/mobile/template-desktop-item27.webp';
import mobileItem28 from '../../assets/images/template/mobile/template-desktop-item28.webp';
import mobileItem29 from '../../assets/images/template/mobile/template-desktop-item29.webp';
import mobileItem30 from '../../assets/images/template/mobile/template-desktop-item30.webp';
import desktopItem1 from '../../assets/images/template/desktop/template-desktop-item1.webp';
import desktopItem2 from '../../assets/images/template/desktop/template-desktop-item2.webp';
import desktopItem3 from '../../assets/images/template/desktop/template-desktop-item3.webp';
import desktopItem4 from '../../assets/images/template/desktop/template-desktop-item4.webp';
import desktopItem5 from '../../assets/images/template/desktop/template-desktop-item5.webp';
import desktopItem6 from '../../assets/images/template/desktop/template-desktop-item6.webp';
import desktopItem7 from '../../assets/images/template/desktop/template-desktop-item7.webp';
import desktopItem8 from '../../assets/images/template/desktop/template-desktop-item8.webp';
import desktopItem9 from '../../assets/images/template/desktop/template-desktop-item9.webp';
import desktopItem10 from '../../assets/images/template/desktop/template-desktop-item10.webp';
import desktopItem11 from '../../assets/images/template/desktop/template-desktop-item11.webp';
import desktopItem12 from '../../assets/images/template/desktop/template-desktop-item12.webp';
import desktopItem13 from '../../assets/images/template/desktop/template-desktop-item13.webp';
import desktopItem14 from '../../assets/images/template/desktop/template-desktop-item14.webp';
import desktopItem15 from '../../assets/images/template/desktop/template-desktop-item15.webp';
import desktopItem16 from '../../assets/images/template/desktop/template-desktop-item16.webp';
import desktopItem17 from '../../assets/images/template/desktop/template-desktop-item17.webp';
import desktopItem18 from '../../assets/images/template/desktop/template-desktop-item18.webp';
import desktopItem19 from '../../assets/images/template/desktop/template-desktop-item19.webp';
import desktopItem20 from '../../assets/images/template/desktop/template-desktop-item20.webp';
import desktopItem21 from '../../assets/images/template/desktop/template-desktop-item21.webp';
import desktopItem22 from '../../assets/images/template/desktop/template-desktop-item22.webp';
import desktopItem23 from '../../assets/images/template/desktop/template-desktop-item23.webp';
import desktopItem24 from '../../assets/images/template/desktop/template-desktop-item24.webp';
import TemplateContentMainTemplate from './TemplateContentMainTemplate';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import { useDispatch } from 'react-redux';
import { selectDesktopTemplate, selectMobileTemplate } from '../../features/templateSlice';
import { webSettingOperate } from '../../features/appSlice';

const TemplateContentMain = (props) => {
    const {
        type,
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        tt59: t("tt59"),
        tt60: t("tt60"),
        tt62: t("tt62"),
    };

    let imageArr = [
        desktopItem1,desktopItem2,desktopItem3,desktopItem4,desktopItem5,desktopItem6,
        desktopItem7,desktopItem8,desktopItem9,desktopItem10,desktopItem11,desktopItem12,
        desktopItem3,desktopItem14,desktopItem15,desktopItem16,desktopItem17,desktopItem18,
        desktopItem19,desktopItem20,desktopItem21,desktopItem22,desktopItem23,desktopItem24,
    ];
    if(type === 0) {
        imageArr = [
            mobileItem1,mobileItem2,mobileItem3,mobileItem4,mobileItem5,mobileItem6,
            mobileItem7,mobileItem8,mobileItem9,mobileItem10,mobileItem11,mobileItem12,
            mobileItem13,mobileItem14,mobileItem15,mobileItem16,mobileItem17,mobileItem18,
            mobileItem19,mobileItem20,mobileItem21,mobileItem22,mobileItem23,mobileItem24,
            mobileItem25,mobileItem26,mobileItem27,mobileItem28,mobileItem29,mobileItem30,
        ];
    }
    let itemArr = [];
    for (let index = 0; index < imageArr.length; index++) {
        itemArr.push(
            {
                id:`template-${type === 0 ? 'mobile' : 'desktop'}`+(index+1),
                image:imageArr[index],
                name:`TT${type === 0 ? '移动' : '桌面'}模板`+(index+1),
            }
        );
    }
    
    const [ lightBox,setLightBox ] = useState(null);

    const handlePreviewClick = (index) => {
        lightBox && lightBox.loadAndOpen(index);
    };
    const handleChooseClick = (index) => {
        if(type === 0) {
            dispatch(selectMobileTemplate({mobile:itemArr[index]}));
        }else {
            dispatch(selectDesktopTemplate({desktop:itemArr[index]}));
        }
        setTimeout(() => {
            dispatch(webSettingOperate({webSettingOpen:true}));
        }, 300);
    };

    useEffect(() => {
        let lightBox = new PhotoSwipeLightbox({
          gallery: '#' + 'browserGallery',
          children: 'a',
          pswpModule: () => import('photoswipe'),
        });
        lightBox.on('uiRegister', function() {
            lightBox.pswp.ui.registerElement({
              name: 'select-button',
              order: 9,
              isButton: true,
              html: `<button class="tt-btn">${textData.tt62}</button>`,
              onClick: (event, el) => {
                const pswp = lightBox.pswp;
                handleChooseClick(pswp.currSlide.index);
                pswp.close();
              }
            });
        });
        lightBox.init();

        setLightBox(lightBox);
    
        return () => {
            lightBox.destroy();
            lightBox = null;
        };
    }, [type,textData.tt62]);

    return (
        <React.Fragment>
            <Grid sx={{
                padding:'43px 58px',
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    '@media': {
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'1080px',
                    }
                }}>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                    }}>
                        <Typography sx={{
                            color:'#000000',
                            fontSize:'31px',
                            fontStyle:'normal',
                            fontWeight:700,
                        }}>
                            {type === 0 ? textData.tt59 : textData.tt60}
                        </Typography>
                        {/* <Button disableRipple sx={{
                            textTransform:'none',
                            bgcolor:'transparent',
                            color:'#000000',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:700,
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'8px',
                        }}>
                            {'View all episodes'}
                            <CardMedia
                                component="img"
                                image={providerArrowIcon}
                                sx={{
                                    width:'12px',
                                    height:'8px',
                                    objectFit:'contain',
                                }}
                            />
                        </Button> */}
                    </Grid>
                    <Grid 
                    className="pswp-gallery" 
                    id={'browserGallery'}
                    container 
                    sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        columnGap:type === 0 ? '32px' : '26px',
                        rowGap:'42px',
                        mt:'38px',
                    }}>
                        {itemArr.map((item,index) => (
                            <TemplateContentMainTemplate 
                                type={type}
                                item={item}
                                previewOnClick={() => handlePreviewClick(index)}
                                chooseOnClick={() => handleChooseClick(index)}
                                key={index}
                            />
                        ))}
                    </Grid>
                </Container>
            </Grid>
        </React.Fragment>
    );
}

export default TemplateContentMain;