import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import logoIcon from '../../assets/images/home/home-ttbet.png';

const Footer = () => {
    return (
        <Grid sx={{
            bgcolor:'#1E212A',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            padding:'18px 0px',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                gap:'18px',
            }}>
                <CardMedia
                    component="img"
                    image={logoIcon}
                    sx={{
                        width:'147px',
                        height:'48px',
                        objectFit:'contain',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {'© TTBET. All Rights Reserved.'}
                </Typography>
            </Box>
        </Grid>
    );
}

export default Footer;