import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Header from '../header/Header';
import bannerIcon from '../../assets/images/home/home-banner.png';
import ttbetIcon from '../../assets/images/home/home-ttbet.png';
import providerBannerIcon from '../../assets/images/home/home-provider-banner.png';
import providerArrowIcon from '../../assets/images/home/home-provider-arrow.png';
import providerIcon1 from '../../assets/images/home/home-provider-1.png';
import providerIcon2 from '../../assets/images/home/home-provider-2.png';
import providerIcon3 from '../../assets/images/home/home-provider-3.png';
import providerIcon4 from '../../assets/images/home/home-provider-4.png';
import providerIcon5 from '../../assets/images/home/home-provider-5.png';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentHeader = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();
    const textData = {
        tt7: t("tt7"),
        tt8: t("tt8"),
        tt9: t("tt9"),
        tt11: t("tt11"),
        tt12: t("tt12"),
        tt13: t("tt13"),
        tt14: t("tt14"),
    };

    const providerImageArr = [
        providerIcon1,providerIcon4,providerIcon2,providerIcon3,providerIcon5,
    ];

    const handleContactUsClick = () => {

    };
    const handleGetStartedClick = () => {
        if(screenMD) {
            navigate(RoutePath.template,{ state: { type:0, } });    
        }else {
            navigate(RoutePath.template,{ state: { type:1, } });    
        }
    };

    return (
        <Grid sx={{
            width:'100%',
            // height:'563px',
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-start',
            position:'relative',
            bgcolor:'#000000',
        }}>
            <CardMedia
                component="img"
                image={bannerIcon}
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                }}
            />

            <Grid container sx={{
                padding:'0px 100px',
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    zIndex:1,
                    mt:'54px',
                    pb:'157px',
                    height:'calc(100% - 54px)',
                    position:'relative',
                    '@media': {
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'880px',
                    }
                }}>
                    <Header menuIndex={0}/>
                    <CardMedia
                        component="img"
                        image={ttbetIcon}
                        sx={{
                            width:'142px',
                            height:'44px',
                            objectFit:'contain',
                            mt:'33px',
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'59px',
                        fontStyle:'normal',
                        fontWeight:700,
                        maxWidth:'550px',
                        mt:'29px',
                        lineHeight:'64px',
                        whiteSpace:'pre-line',
                    }}>
                        {textData.tt7}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'20px',
                        fontStyle:'normal',
                        fontWeight:700,
                        maxWidth:'550px',
                        lineHeight:'64px',
                        whiteSpace:'pre-line',
                    }}>
                        {textData.tt8}
                    </Typography>
                    <Typography sx={{
                        color:'#626B74',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:700,
                        maxWidth:'370px',
                        mt:'20px',
                        whiteSpace:'pre-line',
                    }}>
                        {textData.tt9}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'40px',
                        mt:'30px',
                    }}>
                        <Button variant='text' sx={{
                            textTransform:'none',
                            bgcolor:'#344352',
                            borderRadius:'100px',
                            minHeight:'36px',
                            padding:'4px 40px',
                            color:'#FFFFFF',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:700,
                            '&:hover': {
                                bgcolor:'#344352',
                            }
                        }} onClick={handleContactUsClick}>
                            {textData.tt11}
                        </Button>
                        <Button variant='text' sx={{
                            textTransform:'none',
                            bgcolor:'#344352',
                            borderRadius:'100px',
                            minHeight:'36px',
                            padding:'4px 40px',
                            color:'#FFFFFF',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:700,
                            '&:hover': {
                                bgcolor:'#344352',
                            }
                        }} onClick={handleGetStartedClick}>
                            {textData.tt12}
                        </Button>
                    </Box>

                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        bgcolor:'#FFFFFF',
                        borderRadius:'18px',
                        boxShadow:'0px 4px 18px 0px rgba(0, 0, 0, 0.31)',
                        width:'812px',
                        height:'182px',
                        mt:'66px',
                        zIndex:2,
                        position:'absolute',
                        float:'left',
                        bottom:'-91px',
                        overflow:'hidden',
                    }}>
                        <CardMedia
                            component="img"
                            image={providerBannerIcon}
                            sx={{
                                width:'181px',
                                height:'181px',
                                objectFit:'cover',
                            }}
                        />
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            padding:'28px 28px 28px 22px',
                            width:'calc(100% - 50px)',
                        }}>
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'flex-start',
                                gap:'8px',
                            }}>
                                <Typography sx={{
                                    color:'#000000',
                                    fontSize:'23px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    maxWidth:'420px',
                                    lineHeight:'28px',
                                    whiteSpace:'pre-line',
                                }}>
                                    {textData.tt13}
                                </Typography>
                                {/* <Button disableRipple sx={{
                                    textTransform:'none',
                                    bgcolor:'transparent',
                                    color:'#000000',
                                    fontSize:'12px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    gap:'8px',
                                }}>
                                    {'Episode page'}
                                    <CardMedia
                                        component="img"
                                        image={providerArrowIcon}
                                        sx={{
                                            width:'12px',
                                            height:'8px',
                                            objectFit:'contain',
                                        }}
                                    />
                                </Button> */}
                            </Grid>
                            <Typography sx={{
                                color:'#8E8B8C',
                                fontSize:'9px',
                                fontStyle:'normal',
                                fontWeight:400,
                                mt:'6px',
                            }}>
                                {textData.tt14}
                            </Typography>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                                gap:'13px',
                                width:'560px',
                                mt:'6px',
                                overflow:'scroll',
                                scrollbarWidth: 'none', // Firefox
                                msOverflowStyle: 'none', // Internet Explorer
                                '&::-webkit-scrollbar': {
                                    display: 'none', // Chrome, Safari, Edge
                                },
                            }}>
                                {providerImageArr.map((item,index) => (
                                    <Box sx={{
                                        bgcolor:'#2C3552',
                                        borderRadius:'8px',
                                        width:'112px',
                                        height:'35px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }} key={index}>
                                        <CardMedia
                                            component="img"
                                            image={item}
                                            sx={{
                                                width:'75px',
                                                height:'27px',
                                                objectFit:'contain',
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Grid>            
        </Grid>
    );
}

export default HomeContentHeader;