import React from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import HomeContentMainChoice from './HomeContentMainChoice';
import HomeContentMainAdvantage from './HomeContentMainAdvantage';
import HomeContentMainScrollBanner from './HomeContentMainScrollBanner';
import HomeContentMainContact from './HomeContentMainContact';
import FooterBanner from '../footer/FooterBanner';

const HomeContentMain = () => {

    return (
        <Grid>
            <HomeContentMainChoice />
            <HomeContentMainAdvantage />
            <HomeContentMainScrollBanner />
            <FooterBanner />
            <HomeContentMainContact />
        </Grid>
    );
}

export default HomeContentMain;