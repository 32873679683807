import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import placeholderIcon from '../../assets/images/template/template-setting-placeholder.png';
import { useSelector } from 'react-redux';

const TemplateContentWebSettingItem = (props) => {
    const {
        type,title,desc,isShow,browserOnClick,
    } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        tt69: t("tt69"),
        tt70: t("tt70"),
    };

    const selectMobile = useSelector((state) => state.template.mobile);
    const selectDesktop = useSelector((state) => state.template.desktop);

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            width:'100%',
            height:isShow ? '88px' : '0px',
            transformOrigin:'50% 0 0',
            transition: theme.transitions.create(['height'], {
                duration: 300,
                easing: 'ease-in-out',
            }),
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'8px'
            }}>
                <CardMedia
                    component="img"
                    image={type === 0 ? (selectMobile.image !== null ? selectMobile.image : placeholderIcon) : (selectDesktop.image !== null ? selectDesktop.image : placeholderIcon)}
                    sx={{
                        width:'88px',
                        height:'88px',
                        borderRadius:'8px',
                        objectFit:'cover',
                        overflow:'hidden',
                    }}
                />
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignContent:'center',
                    alignItems:'flex-start',
                    gap:'2px'
                }}>
                    <Typography sx={{
                        color:'#000000',
                        fontSize:'17px',
                        fontStyle:'normal',
                        fontWeight:700,
                    }}>
                        {title}
                    </Typography>
                    <Typography sx={{
                        color:'#8E8B8C',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                    }}>
                        {type === 0 ? (selectMobile.name.length > 0 ? selectMobile.name : textData.tt69) : (selectDesktop.name.length > 0 ? selectDesktop.name : textData.tt69)}
                    </Typography>
                </Box>
            </Box>
            <Button sx={{
                textTransform:'none',
                bgcolor:'transparent',
                height:'32px',
                borderRadius:'16px',
                border:'1px solid #8E8B8C',
                color:'#8E8B8C',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:700,
                padding:'0px 13px',
                '&:hover': {
                    bgcolor:'transparent',
                }
            }} onClick={browserOnClick}>
                {textData.tt70}
            </Button>
        </Grid>
    );
}

export default TemplateContentWebSettingItem;