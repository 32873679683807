import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";

const CustomBaseInput = styled(InputBase)(({ theme }) => ({
    // '& .MuiInputBase-input': {
      position: 'relative',
      border: '1px solid',
      borderColor: 'transparent',
      fontSize: '15px',
      width:'calc(100% - 10px)',
      height:'100%',
      color:'#000000',
      fullWidth:true,
      backgroundColor: '#E4EBF1',
      borderRadius:'8px',
      padding:'0px 10px',
      [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#6D728D',
        opacity: 1,
      },
    // },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#000000',
    },
}));

const CustomInput = (props) => {    
    const { title,filterMatch,placeholder,defaultText,isAutoFocus,handleInputChanged } = props;
    const theme = useTheme();
    // const { focused } = useFormControl() || {};

    // const borderColor = React.useMemo(() => {
    //     return focused ? '#1CA45F' : 'transparent';
    // }, [focused]);

    const handleInputValueChanged = (e) => {
        if(filterMatch !== undefined) {
            const filteredValue = e.target.value.replace(filterMatch, '');
            e.target.value = filteredValue;
        }
        handleInputChanged && handleInputChanged(e);
    }

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
            gap:'8px',
        }}>
            <Typography sx={{
                color:'#000000',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {title}
            </Typography>
            <CustomBaseInput 
                type={'text'} 
                placeholder={placeholder} 
                value={defaultText}
                autoFocus={isAutoFocus}
                onChange={handleInputValueChanged} 
            />
        </Box>
    );
};

const TemplateContentWebSettingBasicInput = (props) => {
    const { title,filterMatch,placeholder,defaultText,isAutoFocus,handleInputChanged } = props;

    return (
        <FormControl sx={{
            width:'100%',
        }}>
             <CustomInput 
                title={title}
                filterMatch={filterMatch}
                placeholder={placeholder} 
                defaultText={defaultText}
                autoFocus={isAutoFocus}
                handleInputChanged={handleInputChanged} 
            />
        </FormControl>
    )
}

export default TemplateContentWebSettingBasicInput;