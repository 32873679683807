import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TemplateContentWebSettingTitle = (props) => {
    const {
        title,added,handleOnClick,
    } = props;
    const theme = useTheme();

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
        }}>
            <Typography sx={{
                color:'#000000',
                fontSize:'20px',
                fontStyle:'normal',
                fontWeight:700,
            }}>
                {title}
            </Typography>
            <Box sx={{
                height:'36px',
                width:'42px',
                position:'relative',
                cursor:'pointer',
            }} onClick={handleOnClick}>
                <Box sx={{
                    bgcolor:'#344352',
                    width:'18px',
                    height:'2px',
                    position:'absolute',
                    left:'12px',
                    top:'17px',
                    opacity:added ? 1 : 0,
                    transition: theme.transitions.create(['opacity'], {
                        duration: 200,
                        easing: 'ease-out',
                    }),
                }}/>
                <Box sx={{
                    bgcolor:'#344352',
                    width:'18px',
                    height:'2px',
                    position:'absolute',
                    left:'12px',
                    top:'17px',
                    transform:added ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transition: theme.transitions.create(['transform'], {
                        duration: 200,
                        easing: 'ease-out',
                    }),
                }}/>
            </Box>
        </Grid>
    );
}

export default TemplateContentWebSettingTitle;