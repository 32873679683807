import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    webSettingOpen:false,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        webSettingOperate(state,action) {
            state.webSettingOpen = action.payload.webSettingOpen;
            console.log('webSettingOperate state:',JSON.stringify(state));
        }
    }
});

export const { webSettingOperate } = appSlice.actions;

export default appSlice.reducer;