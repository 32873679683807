import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomeContentMainSwiper from './HomeContentMainSwiper';

const HomeContentMainScrollBanner = () => {
    const { t } = useTranslation();
    const textData = {
        tt37: t("tt37"),
        tt38: t("tt38"),
    };

    return (
        <Grid sx={{
            bgcolor:'#F8F8F8',
            padding:'58px 0px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'flex-start',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                gap:'12px',
                width:'100%',
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'31px',
                    fontStyle:'normal',
                    fontWeight:700,
                }}>
                    {textData.tt37}
                </Typography>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'11px',
                    fontStyle:'normal',
                    fontWeight:400,
                    maxWidth:'536px',
                    textAlign:'center',
                    whiteSpace:'pre-line',
                }}>
                    {textData.tt38}
                </Typography>
            </Box>
            <HomeContentMainSwiper />
        </Grid>
    );
}

export default HomeContentMainScrollBanner;