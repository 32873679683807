import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Header from '../header/Header';
import bannerIcon from '../../assets/images/template/template-banner.png';
import ttbetIcon from '../../assets/images/home/home-ttbet.png';

const TemplateContentHeader = (props) => {
    const {
        type,
    } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        tt57: t("tt57"),
        tt81: t("tt81"),
        tt11: t("tt11"),
        tt12: t("tt12"),
    };

    const handleContactUsClick = () => {

    };
    const handleGetStartedClick = () => {
        const link = document.createElement('a');
        link.href = 'https://www.ttbetgame.com/';
        link.target = '_blank';
        link.click();
    };

    return (
        <Grid sx={{
            width:'100%',
            // height:'563px',
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-start',
            position:'relative',
            bgcolor:'#000000',
        }}>
            <CardMedia
                component="img"
                image={bannerIcon}
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                }}
            />

            <Grid container sx={{
                padding:'0px 100px',
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    zIndex:1,
                    mt:'54px',
                    pb:'101px',
                    height:'calc(100% - 54px)',
                    position:'relative',
                    '@media': {
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'880px',
                    }
                }}>
                    <Header menuIndex={type + 1}/>
                    <CardMedia
                        component="img"
                        image={ttbetIcon}
                        sx={{
                            width:'142px',
                            height:'44px',
                            objectFit:'contain',
                            mt:'94px',
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'59px',
                        fontStyle:'normal',
                        fontWeight:700,
                        maxWidth:'550px',
                        mt:'29px',
                        lineHeight:'64px',
                        whiteSpace:'pre-line',
                    }}>
                        {textData.tt57}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        maxWidth:'550px',
                        mt:'9px',
                        whiteSpace:'pre-line',
                    }}>
                        {textData.tt81}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'40px',
                        mt:'30px',
                    }}>
                        <Button variant='text' sx={{
                            textTransform:'none',
                            bgcolor:'#344352',
                            borderRadius:'100px',
                            minHeight:'36px',
                            padding:'4px 40px',
                            color:'#FFFFFF',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:700,
                            '&:hover': {
                                bgcolor:'#344352',
                            }
                        }} onClick={handleContactUsClick}>
                            {textData.tt11}
                        </Button>
                        <Button variant='text' sx={{
                            textTransform:'none',
                            bgcolor:'#344352',
                            borderRadius:'100px',
                            minHeight:'36px',
                            padding:'4px 40px',
                            color:'#FFFFFF',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:700,
                            '&:hover': {
                                bgcolor:'#344352',
                            }
                        }} onClick={handleGetStartedClick}>
                            {textData.tt12}
                        </Button>
                    </Box>
                </Container>
            </Grid>            
        </Grid>
    );
}

export default TemplateContentHeader;