import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import bannerIcon from '../../assets/images/template/template-temp-banner.png';

const FooterBanner = () => {
    const { t } = useTranslation();
    const textData = {
        tt45: t("tt45"),
        tt46: t("tt46"),
        tt47: t("tt47"),
    };

    return (
        <Box sx={{
            position:'relative',
            width:'100%',
            height:'448px',
        }}>
            <CardMedia
                component="img"
                image={bannerIcon}
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                }}
            />
            <Box sx={{
                position:'absolute',
                top:'0px',
                left:'0px',
                width:'calc(100% - 214px)',
                height:'100%',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'flex-start',
                gap:'20px',
                padding:'10px 107px',
            }}>
                <Typography sx={{
                    color:'#7CB1FF',
                    fontSize:'48px',
                    fontStyle:'normal',
                    fontWeight:700,
                    lineHeight:'58px',
                    mt:'-30px',
                }}>
                    {textData.tt45}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'56px',
                    fontStyle:'normal',
                    fontWeight:700,
                    lineHeight:'60px',
                }}>
                    {textData.tt46}
                </Typography>
                <Typography sx={{
                    color:'#25B299',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:700,
                    maxWidth:'461px',
                }}>
                    {textData.tt47}
                </Typography>
            </Box>
        </Box>
    );
}

export default FooterBanner;