const MobileBreakpoint = {
    match:'sm',
};

export const Design = {
    width:'375',
    height:'667'
}

export default MobileBreakpoint;
