import React from 'react';
import { useLocation } from 'react-router-dom';
import TemplateContent from '../component/template/TemplateContent';
import WebSetting from '../component/common/WebSetting';

const Template = () => {
    const location = useLocation();
    const type = location.state && location.state.type;

    return (
        <React.Fragment>
            <WebSetting />
            <TemplateContent type={type}/>
        </React.Fragment>
    )
}

export default Template;