import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton, alpha } from '@mui/material';


const TemplateContentWebSettingSelect = (props) => {
    const {
        title,isMultiChoose,isChoose,isFocus,chooseMenuArr,currentSelected,handleChooseArr,handleOpen,handleFocus,
    } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        tt62: t("tt62"),
    }

    const [ chooseArr,setChooseArr ] = useState(currentSelected);
    const [ openMenu,setOpenMenu ] = useState(false);

    const menuHeight = chooseMenuArr.length > 6 ? 271 : (10+16+23*chooseMenuArr.length+18*(chooseMenuArr.length - 1));

    const handleChooseMenuClick = () => {
        setOpenMenu(!openMenu);
        handleOpen && handleOpen(!openMenu,menuHeight);
    };
    const handleClearChooseClick = (e) => {
        e.stopPropagation();

        handleFocus && handleFocus();
        setChooseArr([]);
    };
    const handleRemoveChooseClick = (index) => {
        handleFocus && handleFocus();

        const newArr = chooseArr.filter((currentItem, currentIndex, currentArray) => {
            return currentIndex !== index;
        });
        setChooseArr(newArr);
    };
    const handleMenuItemClick = (index) => {
        if(chooseArr.includes(chooseMenuArr[index])) {
            const tempIndex = chooseArr.indexOf(chooseMenuArr[index]);
            const newArr = chooseArr.filter((currentItem, currentIndex, currentArray) => {
                return currentIndex !== tempIndex;
            });
            setChooseArr(newArr);
            return;
        }
        if(isMultiChoose) {
            let arr = chooseArr.map((item) => item);
            arr.push(chooseMenuArr[index]);
            setChooseArr(arr);
        }else {
            setChooseArr([chooseMenuArr[index]]);
        }
    };

    useEffect(() => {
        setOpenMenu(isChoose);
    }, [isChoose]);
    useEffect(() => {
        handleChooseArr(chooseArr);
    }, [chooseArr]);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
            position:'relative',
            mt:'16px',
        }}>
            <Typography sx={{
                color:'#000000',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {title}
            </Typography>
            <Box sx={{
                bgcolor:'#E4EBF1',
                borderRadius:'8px',
                minHeight:'36px',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                padding:'0px 10px 0px 18px',
                width:'calc(100% - 28px)',
                mt:'8px',
            }} onClick={handleChooseMenuClick}>
                {chooseArr.length === 0 ?
                    <Typography sx={{
                        color:'#8E8B8C',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        width:'320px',
                    }}>
                        {textData.tt62}
                    </Typography>:
                    (
                        isMultiChoose ?
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            padding:'5px 0px',
                            rowGap:'10px',
                            columnGap:'12px',
                            width:'320px',
                        }}>
                            {chooseArr.map((item,index) => (
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    bgcolor:'#000000',
                                    borderRadius:'5px',
                                    height:'26px',
                                }} key={index}>
                                    <Typography sx={{
                                        color:'#FFFFFF',
                                        fontSize:'10px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                        ml:'8px',
                                    }}>
                                        {item}
                                    </Typography>
                                    <IconButton sx={{
                                        width:'22px',
                                        height:'100%',
                                    }} 
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        handleRemoveChooseClick(index)
                                    }}>
                                        {/* <svg height="22" width="22" viewBox="0 0 20 20" aria-hidden="true" fill='none'>
                                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="#FFFFFF"></path>
                                        </svg> */}

                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path d="M0.260422 0.260425C0.607653 -0.0868073 1.17062 -0.0868073 1.51785 0.260425L9.73952 8.48214C10.0868 8.82937 10.0868 9.39234 9.73952 9.73958C9.39229 10.0868 8.82932 10.0868 8.48209 9.73958L0.260423 1.51786C-0.0868074 1.17063 -0.0868077 0.607657 0.260422 0.260425Z" fill="#FFFFFF"/>
                                        <path d="M9.73958 0.260424C10.0868 0.607656 10.0868 1.17063 9.73958 1.51786L1.51791 9.73957C1.17068 10.0868 0.607708 10.0868 0.260478 9.73957C-0.0867521 9.39234 -0.086752 8.82937 0.260478 8.48214L8.48215 0.260424C8.82938 -0.0868079 9.39235 -0.0868081 9.73958 0.260424Z" fill="#FFFFFF"/>
                                        </svg>
                                    </IconButton>
                                </Box>
                            ))}
                        </Grid> :
                        <Typography sx={{
                            color:'#000000',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:400,
                            width:'320px',
                        }}>
                            {chooseArr[0]}
                        </Typography>
                    )
                }
                <IconButton sx={{
                    width:'26px',
                    height:'26px',
                }} onClick={handleClearChooseClick}>
                    {isFocus ? 
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M0.260422 0.260425C0.607653 -0.0868073 1.17062 -0.0868073 1.51785 0.260425L9.73952 8.48214C10.0868 8.82937 10.0868 9.39234 9.73952 9.73958C9.39229 10.0868 8.82932 10.0868 8.48209 9.73958L0.260423 1.51786C-0.0868074 1.17063 -0.0868077 0.607657 0.260422 0.260425Z" fill="#344352"/>
                        <path d="M9.73958 0.260424C10.0868 0.607656 10.0868 1.17063 9.73958 1.51786L1.51791 9.73957C1.17068 10.0868 0.607708 10.0868 0.260478 9.73957C-0.0867521 9.39234 -0.086752 8.82937 0.260478 8.48214L8.48215 0.260424C8.82938 -0.0868079 9.39235 -0.0868081 9.73958 0.260424Z" fill="#344352"/>
                        </svg>
                        :
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M0.260422 0.260425C0.607653 -0.0868073 1.17062 -0.0868073 1.51785 0.260425L9.73952 8.48214C10.0868 8.82937 10.0868 9.39234 9.73952 9.73958C9.39229 10.0868 8.82932 10.0868 8.48209 9.73958L0.260423 1.51786C-0.0868074 1.17063 -0.0868077 0.607657 0.260422 0.260425Z" fill="#B6BEC5"/>
                        <path d="M9.73958 0.260424C10.0868 0.607656 10.0868 1.17063 9.73958 1.51786L1.51791 9.73957C1.17068 10.0868 0.607708 10.0868 0.260478 9.73957C-0.0867521 9.39234 -0.086752 8.82937 0.260478 8.48214L8.48215 0.260424C8.82938 -0.0868079 9.39235 -0.0868081 9.73958 0.260424Z" fill="#B6BEC5"/>
                        </svg>
                    }
                </IconButton>
                <Box sx={{
                    bgcolor:'#B6BEC5',
                    width:'1px',
                    height:'19px',
                }}/>
                <IconButton sx={{
                    width:'28px',
                    height:'28px',
                }} onClick={handleChooseMenuClick}>
                    {isFocus ? 
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <path d="M6.99382e-07 3.81438e-07L6 8L12 1.43051e-06" fill="#344352"/>
                        </svg>
                        :
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <path d="M6.99382e-07 3.81438e-07L6 8L12 1.43051e-06" fill="#B6BEC5"/>
                        </svg>
                    }
                </IconButton>
            </Box>

            {/* 选择菜单 */}
            <Box sx={{
                width:'100%',
                overflow:'hidden',
                height:openMenu ? `${menuHeight}px` : '0px',
                transformOrigin:'50% 0 0',
                transition: theme.transitions.create(['height'], {
                    duration: 300,
                    easing: 'ease-in-out',
                }),
            }}>
                <Box sx={{
                    bgcolor:'#E4EBF1',
                    borderRadius:'8px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'18px',
                    padding:'8px 0px',
                    width:'calc(100% - 16px)',
                    height:`${menuHeight - 25}px`,
                    mt:'8px',
                    overflow:'scroll',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                }}>
                    {chooseMenuArr.map((item,index) => (
                        <Typography sx={{
                            bgcolor:chooseArr.includes(item) ? alpha('#344352',0.5) : 'transparent',
                            color:chooseArr.includes(item) ? '#FFFFFF' : '#000000',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:400,
                            width:'calc(100% - 16px)',
                            height:'23px',
                            cursor:'pointer',
                            padding:'0px 8px',
                            '&:hover': {
                                bgcolor:chooseArr.includes(item) ? alpha('#344352',0.5) : '#B6BEC5',
                                color:chooseArr.includes(item) ? '#FFFFFF' : '#000000',
                            }
                        }} key={index} onClick={() => handleMenuItemClick(index)}>
                            {item}
                        </Typography>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default TemplateContentWebSettingSelect;