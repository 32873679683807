import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import searchIcon from '../../assets/images/template/template-search.png';
import selectedIcon from '../../assets/images/template/template-selected.png';
import './TemplateContentMainTemplate.css';
import { useSelector } from 'react-redux';

const TemplateContentMainTemplate = (props) => {
    const {
        type,item,previewOnClick,chooseOnClick,
    } = props;
    const { id, image, name } = item;
    const { t } = useTranslation();
    const textData = {
        tt61: t("tt61"),
        tt62: t("tt62"),
    };

    const selectMobile = useSelector((state) => state.template.mobile);
    const selectDesktop = useSelector((state) => state.template.desktop);

    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    useEffect(() => {
        const loadImageSize = async () => {
          try {
            const img = new Image();
            img.src = image;
            await img.decode();
            const width = img.width;
            const height = img.height;
            setImageSize({ width, height });
          } catch (error) {
            console.error('Error loading image:', error);
          }
        };
    
        loadImageSize();
    }, [image]);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:type === 0 ? '188px' : '248px',
            bgcolor:'#FFFFFF',
            borderRadius:'8px',
            boxShadow:'0px 0px 15.625px 0px rgba(0, 0, 0, 0.08)',
        }}>
            <a
            href={image}
            data-pswp-width={imageSize.width}
            data-pswp-height={imageSize.height}
            >
                <Box sx={{
                    width:type === 0 ? '188px' : '248px',
                    borderRadius:'8px',
                    height:type === 0 ? '282px' : '241px',
                    position:'relative',
                    overflow:'hidden',
                    border:type === 0 ? ((selectMobile.id.length > 0 && selectMobile.id === id) ? '4px solid #E42F2D' : 'none') : ((selectDesktop.id.length > 0 && selectDesktop.id === id) ? '4px solid #E42F2D' : 'none'),
                }}>
                    <CardMedia
                        component="img"
                        image={image}
                        sx={{
                            position:'absolute',
                            top:'0px',
                            left:'0px',
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                            objectPosition: 'top center',
                        }}
                    />
                    <CardMedia
                        component="img"
                        image={selectedIcon}
                        sx={{
                            display:type === 0 ? ((selectMobile.id.length > 0 && selectMobile.id === id) ? 'block' : 'none') : ((selectDesktop.id.length > 0 && selectDesktop.id === id) ? 'block' : 'none'),
                            position:'absolute',
                            top:'6px',
                            right:'6px',
                            width:'28px',
                            height:'28px',
                            objectFit:'contain',
                        }}
                    />
                </Box>
            </a>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                mt:'12px',
                padding:'0px 8px',
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:700,
                    maxWidth:type === 0 ? '115px' : '160px',
                }}>
                    {name}
                </Typography>
                <Button disableRipple sx={{
                   textTransform:'none',
                   bgcolor:'transparent',
                   color:'#8E8B8C',
                   fontSize:'16px',
                   fontStyle:'normal',
                   fontWeight:400,
                   display:'flex',
                   justifyContent:'flex-start',
                   alignItems:'center',
                   minWidth:'0px',
                   padding:'0px',
                }} onClick={previewOnClick}>
                    <CardMedia
                        component="img"
                        image={searchIcon}
                        sx={{
                            width:'18px',
                            height:'20px',
                            objectFit:'contain',
                        }}
                    />
                    {textData.tt61}
                </Button>
            </Grid>
            <Button sx={{
                textTransform:'none',
                bgcolor:'#344352',
                borderRadius:'18px',
                height:'36px',
                color:'#FFFFFF',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:700,
                width:'100%',
                mt:'16px',
                '&:hover': {
                    bgcolor:'#344352',
                }
            }} onClick={chooseOnClick}>
                {textData.tt62}
            </Button>
        </Box>
    );
}

export default TemplateContentMainTemplate;