
const themeComponent = (mode) => {
    return {
        MuiButton: {
          styleOverrides: {
            root: {
              minWidth:'0px',
              '&:hover': {
                backgroundColor: 'transparent'
              },
              fontFamily: [
                'Satoshi',
                'Ubuntu-Regular',
                '"Helvetica Neue"',
                'Condensed',
                'DisplayRegular',
                'Helvetica',
                'Arial',
                '"PingFang SC"',
                '"Hiragino Sans GB"',
                '"WenQuanYi Micro Hei"',
                '"Microsoft Yahei"',
                'sans-serif',
              ].join(','),
            },
          },
        },
    };
  };
  
  export default themeComponent;