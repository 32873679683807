import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mobile: {
        id:'',
        name:'',
        image:null,
    },
    desktop: {
        id:'',
        name:'',
        image:null,
    },
    websiteName:'',
    websiteID:'',
    languages:[],
    defaultLanguage:'',
    currency:'',
    countryTelCode:'',
}

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        selectMobileTemplate(state,action) {
            state.mobile = action.payload.mobile;
        },
        selectDesktopTemplate(state,action) {
            state.desktop = action.payload.desktop;
        },
        setWebsiteName(state,action) {
            state.websiteName = action.payload.websiteName;
        },
        setWebsiteID(state,action) {
            state.websiteID = action.payload.websiteID;
        },
        setLanguages(state,action) {
            state.languages = action.payload.languages;
        },
        setDefaultLanguage(state,action) {
            state.defaultLanguage = action.payload.defaultLanguage;
        },
        setCurrency(state,action) {
            state.currency = action.payload.currency;
        },
        setCountryTelCode(state,action) {
            state.countryTelCode = action.payload.countryTelCode;
        }
    }
});

export const { 
    selectMobileTemplate,
    selectDesktopTemplate,
    setWebsiteName,
    setWebsiteID,
    setLanguages,
    setDefaultLanguage,
    setCurrency,
    setCountryTelCode,
} = templateSlice.actions;

export default templateSlice.reducer;