import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import usdtIcon from '../../assets/images/home/home-usdt.png';
import choiceIcon from '../../assets/images/home/home-choice.png';
import providerArrowIcon from '../../assets/images/home/home-provider-arrow.png';

const HomeContentMainChoice = () => {
    const { t } = useTranslation();
    const textData = {
        tt15: t("tt15"),
        tt16: t("tt16"),
        tt17: t("tt17"),
        tt18: t("tt18"),
        tt19: t("tt19"),
        tt20: t("tt20"),
        tt21: t("tt21"),
        tt22: t("tt22"),
        tt23: t("tt23"),
    };
    
    return (
        <Grid sx={{
            mt:'174px',
            padding:'0px 100px',
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'928px',
                }
            }}>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                    columnGap:'75px',
                    rowGap:'18px',
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'408px',
                        gap:'23px',
                    }}>
                        <Typography sx={{
                            color:'#000000',
                            fontSize:'31px',
                            fontStyle:'normal',
                            fontWeight:700,
                        }}>
                            {textData.tt15}
                        </Typography>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'13px',
                            width:'100%',
                        }}>
                            <CardMedia
                                component="img"
                                image={usdtIcon}
                                sx={{
                                    width:'44px',
                                    height:'44px',
                                    objectFit:'contain',
                                }}
                            />
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                            }}>
                                <Typography sx={{
                                    color:'#000000',
                                    fontSize:'15px',
                                    fontStyle:'normal',
                                    fontWeight:600,
                                }}>
                                    {textData.tt16}
                                </Typography>
                                <Typography sx={{
                                    color:'#8E8B8C',
                                    fontSize:'12px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                }}>
                                    {textData.tt17}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography sx={{
                            color:'#000000',
                            fontSize:'16px',
                            fontStyle:'normal',
                            fontWeight:400,
                            whiteSpace:'pre-line',
                        }}>
                            <span style={{fontWeight:700}}>{textData.tt18}</span>
                            {`${textData.tt19} \n`}
                            <span style={{fontWeight:700}}>{textData.tt20}</span>
                            {`${textData.tt21}\n`}
                            <span style={{fontWeight:700}}>{textData.tt22}</span>
                            {textData.tt23}
                        </Typography>
                        {/* <Button disableRipple sx={{
                            textTransform:'none',
                            bgcolor:'transparent',
                            color:'#000000',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:700,
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'8px',
                            padding:'0px',
                        }}>
                            {'Episode page'}
                            <CardMedia
                                component="img"
                                image={providerArrowIcon}
                                sx={{
                                    width:'12px',
                                    height:'8px',
                                    objectFit:'contain',
                                }}
                            />
                        </Button> */}
                    </Box>
                    <CardMedia
                        component="img"
                        image={choiceIcon}
                        sx={{
                            width:'428px',
                            height:'328px',
                            objectFit:'contain',
                        }}
                    />
                </Grid>
            </Container>
        </Grid>
        
    );
}

export default HomeContentMainChoice;