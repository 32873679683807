import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import fIcon from '../../assets/images/home/home-contact-f.png';
import inIcon from '../../assets/images/home/home-contact-in.png';
import tIcon from '../../assets/images/home/home-contact-t.png';

const HomeContentMainContactItem = (props) => {
    const {
        item,
    } = props;
    const { image,title,desc, } = item;

    const imageArr = [fIcon,inIcon,tIcon];

    return (
        <Box sx={{
            bgcolor:'#FFFFFF',
            borderRadius:'6px',
            boxShadow:'0px 0px 15.625px 0px rgba(0, 0, 0, 0.08)',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'240px',
            padding:'0px 0px 10px 0px',
        }}>
            <CardMedia
                component="img"
                image={image}
                sx={{
                    width:'100%',
                    height:'306px',
                    borderRadius:'18px',
                    objectFit:'cover',
                }}
            />
            <Typography sx={{
                color:'#000000',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:700,
                mt:'10px',
                ml:'10px',
                mr:'10px',
            }}>
                {title}
            </Typography>
            <Typography sx={{
                color:'#8E8B8C',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:400,
                whiteSpace:'pre-line',
                mt:'10px',
                ml:'10px',
                mr:'10px',
            }}>
                {desc}
            </Typography>
            {/* <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'10px',
                ml:'10px',
                mt:'10px',
                mb:'10px',
            }}>
                {imageArr.map((item,index) => (
                    <CardMedia
                    component="img"
                    image={item}
                    sx={{
                        width:'14px',
                        height:'14px',
                        objectFit:'contain',
                    }}
                    key={index}
                />
                ))}
            </Box> */}
        </Box>
    );
}

export default HomeContentMainContactItem;