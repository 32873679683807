import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import HomeContentMainContactItem from './HomeContentMainContactItem';
import coverIcon1 from '../../assets/images/home/home-contact-cover1.png';
import coverIcon2 from '../../assets/images/home/home-contact-cover2.png';
import coverIcon3 from '../../assets/images/home/home-contact-cover3.png';
import coverIcon4 from '../../assets/images/home/home-contact-cover4.png';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentMainContact = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const textData = {
        tt48: t("tt48"),
        tt11: t("tt11"),
        tt12: t("tt12"),
        tt49: t("tt49"),
        tt50: t("tt50"),
        tt51: t("tt51"),
        tt52: t("tt52"),
        tt53: t("tt53"),
        tt54: t("tt54"),
        tt55: t("tt55"),
        tt56: t("tt56"),
    };

    const itemArr = [
        {
            image:coverIcon1,
            title:textData.tt49,
            desc:textData.tt50,
        },
        {
            image:coverIcon2,
            title:textData.tt51,
            desc:textData.tt52,
        },
        {
            image:coverIcon3,
            title:textData.tt53,
            desc:textData.tt54,
        },
        {
            image:coverIcon4,
            title:textData.tt55,
            desc:textData.tt56,
        },
    ];

    const handleContactUsClick = () => {

    };
    const handleChooseTemplate = () => {
        if(screenMD) {
            navigate(RoutePath.template,{ state: { type:0, } });    
        }else {
            navigate(RoutePath.template,{ state: { type:1, } });    
        }
    };

    return (
        <Grid sx={{
            padding:'48px 50px',
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignContent:'center',
                alignItems:'center',
                gap:'18px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1100px',
                }
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'31px',
                    fontStyle:'normal',
                    fontWeight:700,
                    textAlign:'center',
                }}>
                    {textData.tt48}
                </Typography>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    columnGap:'45px',
                    rowGap:'18px',
                }}>
                    {itemArr.map((item,index) => (
                        <HomeContentMainContactItem 
                            item={item}
                            key={index}
                        />
                    ))}
                </Grid>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'28px',
                }}>
                    <Button variant='text' sx={{
                        textTransform:'none',
                        bgcolor:'#344352',
                        borderRadius:'100px',
                            minHeight:'36px',
                            padding:'4px 40px',
                        color:'#FFFFFF',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:700,
                        '&:hover': {
                            bgcolor:'#344352',
                        }
                    }} onClick={handleContactUsClick}>
                        {textData.tt11}
                    </Button>
                    <Button variant='text' sx={{
                        textTransform:'none',
                        bgcolor:'#344352',
                        borderRadius:'100px',
                            minHeight:'36px',
                            padding:'4px 40px',
                        color:'#FFFFFF',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:700,
                        '&:hover': {
                            bgcolor:'#344352',
                        }
                    }} onClick={handleChooseTemplate}>
                        {textData.tt12}
                    </Button>
                </Box>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContact;